import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import BlogPost from '../components/BlogPost';
import './styles/blogs.styles.css';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import axios from 'axios';
import { Link,useLocation } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner'; // Import TailSpin component

function Blogs() {
  document.title = "Blogs";
  const [currentPage, setCurrentPage] = useState(1);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const postsPerPage = 4;
  const apiKey = "NKAInsiIHw782bubiubiv";

  const location = useLocation();

  useEffect(() => {
    // Check if the URI is /blogs before making the request
    if (location.pathname === '/blogs') {
      const apiUrl = "https://ashifraza.in/api/get_posts.php";
      axios.get(apiUrl, { headers: { 'API_KEY': apiKey } })
        .then(response => {
          setBlogs(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching blog data:', error);
          setLoading(false);
        });
    }
  }, [location.pathname]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const encodePostId = (postId) => {
    return btoa(postId.toString());
  };

  return (
    <div className="container blog-cont">
      <Breadcrumbs title="Blogs" />
      <div className="blogs-page">
        <div className="pagination-arrows">
          {currentPage > 1 && (
            <div className="left-arr" onClick={() => setCurrentPage(currentPage - 1)}>
              <FaArrowLeft />
            </div>
          )}
          {loading ? (
            // Show loader spinner while loading
            <div className="loader-container">
              <div className="loader">
                <TailSpin
                  color="#FFFFFF"
                  height={100}
                  width={100}
                />
              </div>
            </div>
          ) : (
            currentPosts.map(post => (
              <Link to={`/post/${encodePostId(post.id)}`} key={post.id}>
                <BlogPost
                  thumbnail={post.img}
                  author="Muhammad Ashif R."
                  date={post.date}
                  blogtitle={post.title}
                />
              </Link>
            ))
          )}

          {currentPage < Math.ceil(blogs.length / postsPerPage) && (
            <div className="right-arr" onClick={() => setCurrentPage(currentPage + 1)}>
              <FaArrowRight />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Blogs;
