import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import './styles/contact.styles.css';

function Contact() {
  document.title = "Contact Me";

  return (
    <div className="container">
      <Breadcrumbs title="Contact Me" />
      <div className="contact-page">
        <form action="#" method="post">
          <div className="form-control">
            <input type="text" name="name" id='name' required placeholder='Enter Your Name' />
          </div>
          <div className="form-control">
            <input type="email" name="email" id='email' required placeholder='Enter Your Email' />
          </div>
          <div className="form-control">
            <input type="text" name="subject" id='subject' required placeholder='Enter The Subject' />
          </div>
          <div className="form-control">
            <textarea name="message" id="message" cols="30" rows="10" placeholder='Enter Message'></textarea>
          </div>
          <div className="form-control">
            <input type="submit" className='submit-btn' value="Send Message" />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Contact