
const projectsData = [
    {
        thumbnail: "/assests/mlm-project.png",
        name: "Binary MLM System",
        sourceCode: "https://github.com/mdashifraza7786/Binary-MLM-System",
        videoDemo: "https://mlm-project.ashifraza.in"
    },
    {
        thumbnail: "/assests/tic-tac.png", 
        name: "Real-Time Tic-Tac-Toe Game",
        sourceCode: "https://github.com/mdashifraza7786/real-time-tic-tac-toe",
        videoDemo: "https://tictac.ashifraza.in/"
    },
    {
        thumbnail: "/assests/meetingapp.png", 
        name: "MeetingApp-using-zegocloud-API",
        sourceCode: "https://github.com/mdashifraza7786/MeetingApp-using-zegocloud-API",
        videoDemo: "https://meeting-app-blond-eta.vercel.app/"
    },
    {
        thumbnail: "/assests/text-to-voice.png", 
        name: "Text to Voice Generator Using Eleven Lab API",
        sourceCode: "https://github.com/mdashifraza7786/Text-to-Voice-Generator-Using-Eleven-Lab-API",
        videoDemo: "https://ashifraza.in/voicelab/"
    },
    {
        thumbnail: "/assests/intractive-animation.png", 
        name: "Interactive Particle Animation Canvas",
        sourceCode: "https://github.com/mdashifraza7786/InteractiParticle-Animation-Canvas",
        videoDemo: "https://ashifraza.in/intractive-particle-animation/"
    },
    {
        thumbnail: "/assests/fluid-motion-canvas.png", 
        name: "Fluid Motion Particles Animations",
        sourceCode: "https://github.com/mdashifraza7786/Fluid-Motion-Canvas-Responsive-Particle-Animation-with-Dynamic-Connections",
        videoDemo: "https://ashifraza.in/fluid-motion-canvas/"
    },
    
];

export default projectsData;
