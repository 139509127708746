import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import { TailSpin } from 'react-loader-spinner';

function Post() {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const apiKey = "NKAInsiIHw782bubiubiv";

        const fetchPost = async () => {
            try {
                const response = await axios.get(
                    `https://ashifraza.in/api/get_post.php?postId=${id}`,
                    {
                        headers: {
                            'API_KEY': apiKey
                        }
                    }
                );

                setPost(response.data);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching post:', error);
                setLoading(false); // Set loading to false even if there's an error
            }
        };

        fetchPost();
    }, [id]);

    if (loading) {
        return (
            // Show loader spinner while loading
            <div className="loader-container">
                <div className="loader">
                    <TailSpin
                        color="#FFFFFF"
                        height={100}
                        width={100}
                    />
                </div>
            </div>
        );
    }

    if (!post) {
        return <div>Error fetching post. Please try again later.</div>;
    }

    document.title = post.title;

    return (
        <div className="container">
            <div className="post-content">
            <h1 className='blog-title'>{post.title}</h1>
                <div className="post-thumbnail">
                    <img src={post.img} alt="" />
                </div>
                <div className="post-cont" dangerouslySetInnerHTML={{ __html: post.contents }} />
            </div>
        </div>
    );
}

export default Post;
