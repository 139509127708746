import React from 'react';
import './styles/home.styles.css';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";


function Home() {
  document.title = "Muhammad Ashif Raza";

  return (
    <div className="home-page">
      <div className="hero-img">
        <div className="imge-bg">
          <img src="https://muhammad.ashifraza.in/img/ashif-hero1.png" alt="" />
        </div>

      </div>
      <div className="detail">
        <h1 className='welcome-text'>WELCOME</h1>
        <div className="intro">
          <h2><span className='hello-world'>Hello world!</span> <span className='name'>I'm Muhammad Ashif Raza</span></h2>
          <div className="btn-inner">
            <Link to="/projects"><button className='about-me-button'><span>Projects </span><span className='icon-arrow'><FaArrowRight /></span></button></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
