import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import { Link } from 'react-router-dom';
import './styles/about.styles.css';
import { CiFacebook } from "react-icons/ci";
import { CiInstagram } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { FiGithub } from "react-icons/fi";


function About() {
  document.title = "About Me";
  return (
    <div className="container">
      <Breadcrumbs title="About Me" />
      <div className="about-page">
        <div className="about-img">
          <div className="about-imge-bg">
            <img src="https://muhammad.ashifraza.in/img/ashif-hero1.png" alt="" />
          </div>
          <div className="social-icons">
            <a target='_blank' href="https://github.com/mdashifraza7786"><li><FiGithub /><span>Github</span></li></a>
            <a target='_blank' href="https://www.linkedin.com/in/muhammad-ashif-raza-02181b269"><li><CiLinkedin /><span>Linkedin</span></li></a>
            <a target='_blank' href="https://www.facebook.com/AshifRaza.119896"><li><CiFacebook /><span>Facebook</span></li></a>
            <a target='_blank' href="https://instagram.com/Muhammad_ashif_r"><li><CiInstagram /><span>Instagram</span></li></a>
          </div>
          <div className="download-re">
            <a target='_blank' href="https://ashifraza.in/assets/files/muhammad_ashif_raza.pdf"><button>Download Resume</button></a>
          </div>
        </div>
        <div className="detail">
          <h1 className='about-name'>Md Ashif Raza</h1>
          <p className='about-me--paragraph'>
            My name is Muhammad Ashif Raza, and I am a sophomore student pursuing B.tech in Computer Science and Engineering at the esteemed National Institute of Technology, Rourkela (NITR). Additionally, I possess a strong background as a professional full stack web developer, with a solid track record of 4 years of experience in the field. Apart from my academic and professional pursuits, I have a passion for Hindi/Urdu poetry. I take great pleasure in both reading and composing verses in these beautiful languages.
          </p>
        </div>
      </div>

    </div>
  )
}

export default About