import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Blogs from './pages/Blogs';
import Contact from './pages/Contact';
import Menus from './components/Menus';
import Skills from './pages/Skills';
import Post from './pages/Post';
import Projects from './pages/Projects';
function App() {
  return (
    <BrowserRouter>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/blogs" element={<Blogs/>} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/skills" element={<Skills/>} />
            <Route path="/post/:id" element={<Post/>} />
            <Route path="*" element={<Home/>} />
          </Routes>
          <Menus/>
    </BrowserRouter>
  );
}

export default App;
