import React from 'react';
import { FaHtml5 } from "react-icons/fa";
import { IoLogoCss3 } from "react-icons/io5";
import { TbBrandJavascript } from "react-icons/tb";
import { BiLogoPhp } from "react-icons/bi";
import { SiMysql } from "react-icons/si";
import { DiJqueryLogo } from "react-icons/di";
import { TbBrandCpp } from "react-icons/tb";
import { RiReactjsFill } from "react-icons/ri";
import { FaNodeJs } from "react-icons/fa";
import { FaJava } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { TbBrandPython } from "react-icons/tb";
import { BsBootstrap } from "react-icons/bs";
import { FaGitAlt } from "react-icons/fa";


function Langcard(props) {
    const iconComponents = {
        FaHtml5,
        IoLogoCss3,
        TbBrandJavascript,
        BiLogoPhp,
        SiMysql,
        DiJqueryLogo,
        TbBrandCpp,
        RiReactjsFill,
        FaNodeJs,
        FaJava,
        SiExpress,
        BsBootstrap,
        TbBrandPython,
        FaGitAlt
    };

    const IconComponent = iconComponents[props.icon];

    return (
        <div className="card-outer">
            <div className="langorframework">
                <p>{props.name}</p>
            </div>
            <div className="card">
                <div className="card-icon">
                    <IconComponent />

                </div>

            </div>
            
        </div>
    );
}

export default Langcard;
