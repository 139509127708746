import React, { useState } from 'react';
import Breadcrumbs from '../components/Breadcrumbs'
import Langcard from '../components/Langcard';
import './styles/skills.styles.css';


function Skills() {
  document.title = "My Skills";

    const [activeButton, setActiveButton] = useState('Languages');
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };
    return (
        <div className="container">
            <div className="skills-page">
                <Breadcrumbs title="Skills" />
                <div className="sections-cards">
                    <div className="types">
                        <button className={activeButton === 'Languages' ? 'active' : ''} onClick={() => handleButtonClick('Languages')}>Languages</button>
                        <button className={activeButton === 'Frameworks' ? 'active' : ''} onClick={() => handleButtonClick('Frameworks')}>Frameworks</button>
                        <button className={activeButton === 'Other' ? 'active' : ''} onClick={() => handleButtonClick('Other')}>Other</button>
                    </div>
                    <div className="cards-outer">
                        <div className="cards">
                            {activeButton === 'Languages' && (
                                <>
                                    <Langcard icon="FaHtml5" name="HTML" />
                                    <Langcard icon="IoLogoCss3" name="CSS" />
                                    <Langcard icon="TbBrandJavascript" name="JAVASCRIPT" />
                                    <Langcard icon="FaJava" name="JAVA" />
                                    <Langcard icon="BiLogoPhp" name="PHP" />
                                    <Langcard icon="SiMysql" name="MYSQL" />
                                    <Langcard icon="TbBrandPython" name="PYTHON" />
                                    <Langcard icon="TbBrandCpp" name="C++" />
                                </>
                            )}
                            {activeButton === 'Frameworks' && (
                                <>
                                    <Langcard icon="DiJqueryLogo" name="JQUERY" />
                                    <Langcard icon="BsBootstrap" name="BOOTSTRAP" />
                                    <Langcard icon="SiExpress" name="EXPRESS.JS" />
                                    <Langcard icon="RiReactjsFill" name="REACT.JS" />
                                    <Langcard icon="FaNodeJs" name="NODE.JS" />
                                </>
                            )}
                            {activeButton === 'Other' && (
                                <>
                                    <Langcard icon="FaGitAlt" name="GIT" />
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills