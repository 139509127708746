import React from 'react'

const ProjectBox = (props) => {
  return (
    <div className='project-box'>
      <div className="img-container">
        <img src={props.thumbnail} alt={props.name} />
        <div className="title-cont">
          <h4>{props.name}</h4>
        </div>
      </div>
      <div className="viewbutton">
        <a href={props.sc}><button>Source Code</button></a>
        <a href={props.vd}><button>View Demo</button></a>
      </div>
    </div>
  )
}

export default ProjectBox