import React, { useRef, useEffect } from 'react';

function BlogPost(props) {
    const titleRef = useRef(null);

    useEffect(() => {
        applyEllipsis(titleRef.current);
    }, []);

    function applyEllipsis(element) {
        const maxHeight = 100; // Maximum height in pixels
        const lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
        const maxLines = Math.floor(maxHeight / lineHeight);

        while (element.scrollHeight > maxHeight) {
            element.textContent = element.textContent.replace(/\W*\s(\S)*$/, '...');
        }
    }

    return (
        <div className="BlogPost">
            <div className="post-img">
                <img src={props.thumbnail} alt={props.blogtitle} />
            </div>
            <div className='post-detail'>
                <span>By {props.author}</span>
                <span>{props.date}</span>
            </div>
            <div className="post-title" ref={titleRef}>
                <p>{props.blogtitle}</p>
            </div>
        </div>
    );
}

export default BlogPost;
