import React from 'react'
import { Link } from 'react-router-dom'

function Breadcrumbs(props) {
    return (
        <div className="breadcrumb">
            <h1 className='breadcrumb-title'>{props.title}</h1>
            <h2 className='breadcrumb-path'><Link to="/">Home</Link> / {props.title}</h2>
        </div>
    )
}

export default Breadcrumbs