import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import './styles/projects.style.css';
import ProjectBox from '../components/ProjectBox';
import projectsData from '../data/projects'; 

function Projects() {
    document.title = "Projects";

    return (
        <div className='container'>
            <Breadcrumbs title="Projects" /> 
            <div className="project-page">
                <div className="projects-container">
                    {projectsData.map((project, index) => (
                        <ProjectBox
                            key={index}
                            thumbnail={project.thumbnail}
                            name={project.name}
                            sc={project.sourceCode}
                            vd={project.videoDemo}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Projects;
