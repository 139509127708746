import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { IoMdContact } from "react-icons/io";
import { SiAboutdotme } from "react-icons/si";
import { LiaBlogSolid } from "react-icons/lia";
import { FaBookOpen } from "react-icons/fa";
import { GrProjects } from "react-icons/gr";

function Menus() {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState('');

    useEffect(() => {
        
        const pathname = location.pathname;
        if (pathname === '/') {
            setActiveItem('home');
        } else if (pathname === '/skills') {
            setActiveItem('skills');
        } else if (pathname === '/blogs') {
            setActiveItem('blogs');
        } else if (pathname === '/about') {
            setActiveItem('about');
        } else if (pathname === '/contact') {
            setActiveItem('contact');
        } else {
            setActiveItem('');
        }
    }, [location]);

    return (
        <div className="fixed-menu">
            <div className='b-menus'>
                <ul className='menus'>
                    <Link to="/" onClick={() => setActiveItem('home')}><li className={activeItem === 'home' ? 'active' : ''}><span>HOME</span><FaHome/></li></Link>
                    <Link to="/skills" onClick={() => setActiveItem('skills')}><li className={activeItem === 'skills' ? 'active' : ''}><span>Skills</span><FaBookOpen/></li></Link>
                    <Link to="/blogs" onClick={() => setActiveItem('blogs')}><li className={activeItem === 'blogs' ? 'active' : ''}><span>Blogs</span><LiaBlogSolid/></li></Link>
                    <Link to="/about" onClick={() => setActiveItem('about')}><li className={activeItem === 'about' ? 'active' : ''}><span>About Me</span><SiAboutdotme/></li></Link>
                    <Link to="/contact" onClick={() => setActiveItem('contact')}><li className={activeItem === 'contact' ? 'active' : ''}><span>Contact Me</span><IoMdContact/></li></Link>
                </ul>
            </div>
        </div>
    );
}

export default Menus;
